var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"height":"100%"}},[_c('div',{staticClass:"drawer-header d-flex align-center mb-4"},[_c('span',{staticClass:"font-weight-semibold text-base text--primary"},[_vm._v(" Add Banner ")])]),_c('v-card-text',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"box"},[_vm._l((_vm.banner_data),function(field){return _c('div',{key:field},[_c('div',{staticClass:"row col-12"},[_c('v-text-field',{staticClass:"mb-6",attrs:{"outlined":"","dense":"","label":"Name *","hide-details":"auto","rules":[_vm.validators.required],"error-messages":_vm.errors.name},on:{"change":function($event){_vm.errors.name = []}},model:{value:(field.name),callback:function ($$v) {_vm.$set(field, "name", $$v)},expression:"field.name"}}),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Live from date *","readonly":"","outlined":"","dense":"","error-messages":_vm.errors.live_from,"rules":[_vm.validators.required]},on:{"change":function($event){_vm.errors.live_from = []}},model:{value:(_vm.liveFromDate),callback:function ($$v) {_vm.liveFromDate=$$v},expression:"liveFromDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.liveFromDatePickerShow),callback:function ($$v) {_vm.liveFromDatePickerShow=$$v},expression:"liveFromDatePickerShow"}},[_c('v-date-picker',{attrs:{"id":field,"max":_vm.liveUptoDate},on:{"input":function($event){_vm.liveFromDatePickerShow = false}},model:{value:(_vm.liveFromDate),callback:function ($$v) {_vm.liveFromDate=$$v},expression:"liveFromDate"}})],1)],1),_c('div',{staticClass:"row col-12"},[_c('v-menu',{ref:"liveFromTimePicker",refInFor:true,attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.liveFromTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.liveFromTime=$event},"update:return-value":function($event){_vm.liveFromTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Live from time *","readonly":"","outlined":"","dense":"","error-messages":_vm.errors.live_from,"rules":[_vm.validators.required]},on:{"change":function($event){_vm.errors.live_from = []}},model:{value:(_vm.liveFromTime),callback:function ($$v) {_vm.liveFromTime=$$v},expression:"liveFromTime"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.liveFromTimePickerShow),callback:function ($$v) {_vm.liveFromTimePickerShow=$$v},expression:"liveFromTimePickerShow"}},[(_vm.liveFromTimePickerShow)?_c('v-time-picker',{attrs:{"full-width":"","max":_vm.isSameDayTimeEntry?_vm.liveUptoTime:null},on:{"click:minute":function($event){return _vm.$refs.liveFromTimePicker.save(_vm.liveFromTime)}},model:{value:(_vm.liveFromTime),callback:function ($$v) {_vm.liveFromTime=$$v},expression:"liveFromTime"}}):_vm._e()],1),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Live upto date *","readonly":"","outlined":"","dense":"","error-messages":_vm.errors.live_upto,"rules":[_vm.validators.required]},on:{"change":function($event){_vm.errors.live_upto = []}},model:{value:(_vm.liveUptoDate),callback:function ($$v) {_vm.liveUptoDate=$$v},expression:"liveUptoDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.liveUptoDatePickerShow),callback:function ($$v) {_vm.liveUptoDatePickerShow=$$v},expression:"liveUptoDatePickerShow"}},[_c('v-date-picker',{attrs:{"min":_vm.liveFromDate},on:{"input":function($event){_vm.liveUptoDatePickerShow = false}},model:{value:(_vm.liveUptoDate),callback:function ($$v) {_vm.liveUptoDate=$$v},expression:"liveUptoDate"}})],1)],1),_c('div',{staticClass:"row col-12"},[_c('div',{staticClass:"row col-5"},[_c('v-menu',{ref:"liveUptoTime",refInFor:true,attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.liveUptoTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.liveUptoTime=$event},"update:return-value":function($event){_vm.liveUptoTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Live upto time *","readonly":"","outlined":"","dense":"","error-messages":_vm.errors.live_upto,"rules":[_vm.validators.required]},on:{"change":function($event){_vm.errors.live_upto = []}},model:{value:(_vm.liveUptoTime),callback:function ($$v) {_vm.liveUptoTime=$$v},expression:"liveUptoTime"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.liveUptoTimePickerShow),callback:function ($$v) {_vm.liveUptoTimePickerShow=$$v},expression:"liveUptoTimePickerShow"}},[(_vm.liveUptoTimePickerShow)?_c('v-time-picker',{attrs:{"full-width":"","min":_vm.isSameDayTimeEntry?_vm.liveFromTime:null},on:{"click:minute":function($event){return _vm.$refs.liveUptoTime.save(_vm.liveUptoTime)}},model:{value:(_vm.liveUptoTime),callback:function ($$v) {_vm.liveUptoTime=$$v},expression:"liveUptoTime"}}):_vm._e()],1)],1)]),_c('div',{staticClass:"row col-12"},[(field.image !== null)?_c('v-text-field',{staticClass:"mb-6",attrs:{"value":field.image.filename,"readonly":"","clearable":"","outlined":"","dense":"","label":"Image file","hide-details":"auto","error-messages":_vm.errors.image_file},on:{"click:clear":_vm.clearImage}}):_vm._e(),(field.image === null)?_c('v-file-input',{staticClass:"mb-6",attrs:{"prepend-icon":"","aspect-ratio":16/9,"accept":"image/*","label":"Image file","outlined":"","dense":"","preplive":"","upto-icon":"","hide-details":"auto","clearable":"","error-messages":_vm.errors.image_file},on:{"change":_vm.loadImage},model:{value:(field.image_file),callback:function ($$v) {_vm.$set(field, "image_file", $$v)},expression:"field.image_file"}}):_vm._e(),_c('v-img',{attrs:{"src":_vm.url,"width":"20%","height":"15%"}})],1)])}),_c('v-btn',{attrs:{"color":"primary","block":"","type":"button"},on:{"click":_vm.AddField}},[_vm._v(" Add new ")]),_c('v-btn',{attrs:{"color":"primary","block":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.formType === 'editItem'?'Save':'Add')+" ")])],2)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }