<template>
  <div>
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">
          Add Banner
        </span>
      </div>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <div class="box">
            <div
              v-for="field in banner_data"
              :key="field"
            >
              <div class="row col-12">
                <v-text-field
                  v-model="field.name"
                  outlined
                  dense
                  label="Name *"
                  hide-details="auto"
                  class="mb-6"
                  :rules="[validators.required]"
                  :error-messages="errors.name"
                  @change="errors.name = []"
                ></v-text-field>
                <v-spacer></v-spacer>
                <!--Live from date field -->
                <v-menu
                  v-model="liveFromDatePickerShow"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="liveFromDate"
                      label="Live from date *"
                      readonly
                      v-bind="attrs"
                      outlined
                      dense
                      :error-messages="errors.live_from"
                      :rules="[validators.required]"
                      v-on="on"
                      @change="errors.live_from = []"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :id="field"
                    v-model="liveFromDate"
                    :max="liveUptoDate"
                    @input="liveFromDatePickerShow = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="row col-12">
                <!-- Live from Time -->
                <v-menu
                  ref="liveFromTimePicker"
                  v-model="liveFromTimePickerShow"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="liveFromTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="liveFromTime"
                      label="Live from time *"
                      readonly
                      v-bind="attrs"
                      outlined
                      dense
                      :error-messages="errors.live_from"
                      :rules="[validators.required]"
                      v-on="on"
                      @change="errors.live_from = []"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="liveFromTimePickerShow"
                    v-model="liveFromTime"
                    full-width
                    :max="isSameDayTimeEntry?liveUptoTime:null"
                    @click:minute="$refs.liveFromTimePicker.save(liveFromTime)"
                  ></v-time-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <!-- Live upto date field -->
                <v-menu
                  v-model="liveUptoDatePickerShow"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="liveUptoDate"
                      label="Live upto date *"
                      readonly
                      v-bind="attrs"
                      outlined
                      dense
                      :error-messages="errors.live_upto"
                      :rules="[validators.required]"
                      v-on="on"
                      @change="errors.live_upto = []"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="liveUptoDate"
                    :min="liveFromDate"
                    @input="liveUptoDatePickerShow = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="row col-12">
                <!-- Live upto Time -->
                <div class="row col-5">
                  <v-menu
                    ref="liveUptoTime"
                    v-model="liveUptoTimePickerShow"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="liveUptoTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="liveUptoTime"
                        label="Live upto time *"
                        readonly
                        v-bind="attrs"
                        outlined
                        dense
                        :error-messages="errors.live_upto"
                        :rules="[validators.required]"
                        v-on="on"
                        @change="errors.live_upto = []"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="liveUptoTimePickerShow"
                      v-model="liveUptoTime"
                      full-width
                      :min="isSameDayTimeEntry?liveFromTime:null"
                      @click:minute="$refs.liveUptoTime.save(liveUptoTime)"
                    ></v-time-picker>
                  </v-menu>
                </div>
              </div>
              <div class="row col-12">
                <v-text-field
                  v-if="field.image !== null"
                  :value="field.image.filename"
                  readonly
                  clearable
                  outlined
                  dense
                  label="Image file"
                  hide-details="auto"
                  class="mb-6"
                  :error-messages="errors.image_file"
                  @click:clear="clearImage"
                ></v-text-field>
                <v-file-input
                  v-if="field.image === null"
                  v-model="field.image_file"
                  prepend-icon=""
                  :aspect-ratio="16/9"
                  accept="image/*"
                  label="Image file"
                  outlined
                  dense
                  preplive
                  upto-icon=""
                  class="mb-6"
                  hide-details="auto"
                  clearable
                  :error-messages="errors.image_file"
                  @change="loadImage"
                ></v-file-input>

                <v-img
                  :src="url"
                  width="20%"
                  height="15%"
                ></v-img>
              </div>
            </div>
            <v-btn
              color="primary"
              block
              type="button"
              @click="AddField"
            >
              Add new
            </v-btn>
            <v-btn
              color="primary"
              block
              type="submit"
            >
              {{ formType === 'editItem'?'Save':'Add' }}
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'
import { required } from '@core/utils/validation'
import _ from 'lodash'
import CONSTANTS from '../../constants'

const blankFormData = {
	name: '',
	message: '',
	live_from: null,
	live_upto: null,
	image_file: null,
	image_url: '',
	image: null,
}

export default {
	name: 'CreateBanner',
	data() {
		return {
			url: null,
			tab: 0,
			formData: _.cloneDeep(blankFormData),
			form: null,
			valid: true,
			errors: {},
			validators: { required },
			icons: { mdiClose },
			liveFromDatePickerShow: false,
			liveFromTimePickerShow: false,
			liveUptoDatePickerShow: false,
			liveUptoTimePickerShow: false,
			banner_data: [_.cloneDeep(blankFormData)],
		}
	},
	computed: {
		isVisible: {
			get() {
				return this.show
			},
			set(val) {
				this.$emit('update:show', val)
			},
		},
		isSameDayTimeEntry() {
			return this.liveFromDate && this.liveFromDate === this.liveUptoDate
		},
		liveFromDate: {
			get() {
				return this.formData.live_from != null ? this.formData.live_from.split(' ')[0] : ''
			},
			set(val) {
				const liveFromDate = val != null ? val : ''
				const liveFromTime = this.liveFromTime != null ? this.liveFromTime : ''
				this.formData.live_from = `${liveFromDate} ${liveFromTime}`
			},
		},
		liveFromTime: {
			get() {
				return this.formData.live_from != null ? this.formData.live_from.split(' ')[1] : ''
			},
			set(val) {
				const liveFromDate = this.liveFromDate != null ? this.liveFromDate : ''
				const liveFromTime = val != null ? val : ''
				this.formData.live_from = `${liveFromDate} ${liveFromTime}`
			},
		},
		liveUptoDate: {
			get() {
				return this.formData.live_upto != null ? this.formData.live_upto.split(' ')[0] : ''
			},
			set(val) {
				const liveUptoDate = val != null ? val : ''
				const liveUptoTime = this.liveUptoTime != null ? this.liveUptoTime : ''
				this.formData.live_upto = `${liveUptoDate} ${liveUptoTime}`
			},
		},
		liveUptoTime: {
			get() {
				return this.formData.live_upto != null ? this.formData.live_upto.split(' ')[1] : ''
			},
			set(val) {
				const liveUptoDate = this.liveUptoDate != null ? this.liveUptoDate : ''
				const liveUptoTime = val != null ? val : ''
				this.formData.live_upto = `${liveUptoDate} ${liveUptoTime}`
			},
		},
	},
	methods: {
		clearImage() {
			this.formData.image = null
			this.loadImage()
		},
		loadImage() {
			this.errors.image_file = []
			if (this.formData.image_file != null) {
				this.url = URL.createObjectURL(this.formData.image_file)
			} else if (this.formData.image != null) {
				this.url = this.formData.image_url
			} else {
				this.url = CONSTANTS.NO_IMAGE
			}
		},
		resetFormValidation() {
			this.$refs.form.resetValidation()
			this.$set(this, 'errors', {})
			this.$set(this, 'valid', this.formType !== 'addItem')
		},
		loadFormData(data) {
			this.resetFormValidation()
			this.formData = _.cloneDeep(data)
			this.loadImage()
		},
		prepareFromData() {
			const formData = new FormData()
			Object.entries(this.formData).forEach(([key, value]) => {
				formData.append(key, value)
			})

			return formData
		},
		store() {
			const formData = this.prepareFromData()
			this.$store
				.dispatch('banner/store', formData)
				.then(response => {
					this.isVisible = false
					this.$emit('onComplete')
					this.$store.commit('app/SET_SNACKBAR_SUCCESS', response.data?.message)
				})
				.catch(error => {
					const { errors } = error.response.data
					if (errors) {
						this.$set(this, 'errors', errors)
						this.$set(this, 'valid', false)
					}
					this.$store.commit('app/SET_SNACKBAR_ERROR', error.response.data?.message)
				})
		},
		update() {
			const formData = this.prepareFromData()
			this.$store
				.dispatch('banner/update', formData)
				.then(response => {
					this.isVisible = false
					this.$emit('onComplete')
					this.$store.commit('app/SET_SNACKBAR_SUCCESS', response.data?.message)
				})
				.catch(error => {
					const { errors } = error.response.data
					if (errors) {
						this.$set(this, 'errors', errors)
						this.$set(this, 'valid', false)
					}
					this.$store.commit('app/SET_SNACKBAR_ERROR', error.response.data?.message)
				})
		},
		onSubmit() {
			if (this.valid) {
				const methodType = this.formType === 'editItem' ? 'update' : 'store'
				this[methodType]()
			} else {
				this.$refs.form.validate()
			}
		},
		AddField() {
			this.banner_data.push(blankFormData)
		},
		deleteRow(index) {
			this.banner_data.splice(index, 1)
		},
	},
}
</script>

<style scoped>

</style>
